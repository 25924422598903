<template>
  <my-header></my-header>
  <div class="container">
    <div class="top">
      <div class="top_title">标题</div>
      <input class="top_input" v-model="title" placeholder="请输入标题">
    </div>
    <div class="mid">
      <div class="mid_title">正文内容</div>
      <div class="mid_edit" ref="editorElem"></div>
    </div>
    <div class="bottom">
      <div class="btn">提交</div>
    </div>
  </div>

</template>

<script>
  import MyHeader from '@/components/MyHeader.vue'
  import E from 'wangeditor'
  export default {
    name: "admin_news",
    components: {
      MyHeader
    },
    data(){
      return{
        editor: '', // 富文本编辑器内容
      }
    },
    methods:{},
    created(){

    },
    mounted(){
      this.editor = new E(this.$refs.editorElem)
      this.editor.create() // 创建富文本实例
    },


  };
</script>

<style lang="stylus" scoped>
  .container
    display flex
    flex-flow column
    box-sizing border-box
    padding 60px 140px 140px 140px
    .top
      display flex
      flex-flow column
      .top_title
        font-size 26px
      .top_input
        margin-top 18px
        border 1px solid #eeeeee
        height 68px
        border-radius 16px
        box-sizing border-box
        padding 0 32px
        outline thistle
        font-size 26px
      .top_input:focus{
        border-color #007BFF
      }
    .mid
      margin-top 58px
      .mid_title
        font-size 26px
        margin-bottom 18px

    .bottom
      width 100%
      display flex
      align-items center
      justify-content center
      .btn
        width 180px
        height 56px
        line-height 56px
        font-size 22px
        text-align center
        background #007BFF
        color #fff
        border-radius 16px
        margin-top 38px
        cursor pointer
</style>
